<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb" aria-current="page">
                        <li class="breadcrumb-item">
                            <a class="breadcrumb-link" :href="homeUrl">{{ homeText }}</a>
                        </li>
                        <li class="breadcrumb-item" v-for="item in breadcrumb" :key="item">
                            <template v-if="item.slug == this.slug">
                                <span class="breadcrumb-link">{{ item.name }}</span>
                            </template>
                            <template v-else>
                                <a class="breadcrumb-link" :href="item.url">{{ item.name }}</a>
                            </template>
                        </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from 'axios'

    export default {
        props: ["slug", "homeUrl", "homeText"],
        data () {
            return {
                breadcrumb: null,
            }
        },
        mounted() {

            let vm = this
            if(this.slug) {
                const lang = document.documentElement.lang
                const token = document.head.querySelector("[name~=csrf-token]").content

                const $endpoint = `/api/${lang}/breadcrumbs/${this.slug}?token=${token}`;
                axios.get($endpoint).then(function (response) {
                    let code = response.data.code  
                    if (code == 200) {
                        vm.breadcrumb = response.data.data
                    }
                })
            }

        }

    }
</script>