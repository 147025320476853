<template>
    <div class="banner">
        <div class="banner__image">
            <img :src="this.src" :alt="this.title">
        </div>
        <div class="banner__body">            
            <breadcrumbs :slug="this.slug" :homeUrl="this.homeUrl" :homeText="this.homeText"></breadcrumbs>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="banner__title" v-html="this.title"></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumbs from './Breadcrumb.vue'
export default {
    components: {
        breadcrumbs: Breadcrumbs
    },
    props: ["src", "title", "slug", "homeUrl", "homeText"],
}
</script>