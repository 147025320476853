<template>
  <div class="row mb-5 pb-5">
    <div class="col-lg-12">
      <nav-links
        :text1="this.text1"
        :text2="this.text2"
        :text3="this.text3"
        :text4="this.text4"
        :text5="this.text5"
        :text6="this.text6"
        :text7="this.text7"
        :text8="this.text8"
        :text9="this.text9"
        :text10="this.text10"
        :text11="this.text11"
        :search="search"
        @setSearch="setSearch"
      >
      </nav-links>

      <form class="form form--advance-search" method="get" action="javascript:void(0);">
        <div class="footer__row">
          <div class="col-md-auto">
            <div class="form-search">
              <input
                type="text"
                class="form-search__input"
                name="Search"
                :placeholder="this.textsearch"
                :value="keyword"
                @input="
                  debounce(() => {
                    keyword = $event.target.value;
                  })
                "
              />
              <button class="form-search__icon"><i :class="iconStyle"></i></button>
              <!-- <span class="form-search__logo">
                Enhanced by&nbsp;
                <a :href="this.siurl" target="_blank"
                  ><img :src="this.silogo" alt="shareinvestorthailand" /></a
              ></span> -->
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <template v-if="!firstload">
    <site-search
      :items="setItems(items.siteSearch)"
      :keyword="keyword"
      :search="search"
      :title="this.text2"
      :textreadmore="this.textreadmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></site-search>

    <food
      :items="setItems(items.food)"
      :keyword="keyword"
      :search="search"
      :title="this.text3"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></food>

    <non-food
      :items="setItems(items.nonFood)"
      :keyword="keyword"
      :search="search"
      :title="this.text4"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></non-food>

    <pharmaceutical-and-nutraceutical
      :items="setItems(items.pharmaceuticalAndNutraceutical)"
      :keyword="keyword"
      :search="search"
      :title="this.text5"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></pharmaceutical-and-nutraceutical>

    <new-product-highlights
      :items="setItems(items.newProductHighlights)"
      :keyword="keyword"
      :search="search"
      :title="this.text6"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></new-product-highlights>

    <solution-partner
      :items="setItems(items.solutionPartner)"
      :keyword="keyword"
      :search="search"
      :title="this.text7"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></solution-partner>

    <explore-publications
      :items="setItems(items.explorePublications)"
      :keyword="keyword"
      :search="search"
      :title="this.text8"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></explore-publications>

    <ingredient-functions
      :items="setItems(items.ingredientFunctions)"
      :keyword="keyword"
      :search="search"
      :title="this.text9"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></ingredient-functions>

    <solution-portfolio
      :items="setItems(items.solutionPortfolio)"
      :keyword="keyword"
      :search="search"
      :title="this.text10"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></solution-portfolio>

    <news
      :items="setItems(items.news)"
      :keyword="keyword"
      :search="search"
      :title="this.text11"
      :textreadmore="this.textreadmore"
      :textviewmore="this.textviewmore"
      :textnoresult="textnoresult"
      :token="this.token"
      :lang="this.lang"
      @updateItems="updateItems"
      @setLoading="setLoading"
    ></news>
  </template>

  <div class="preloader-search" :class="reloadStyle">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
let axios = require("axios");

import NavLinks from "./NavLinks.vue";
import SiteSearch from "./SiteSearch.vue";
import Food from "./Food.vue";
import NonFood from "./NonFood.vue";
import PharmaceuticalAndNutraceutical from "./PharmaceuticalAndNutraceutical.vue";
import NewProductHighlights from "./NewProductHighlights.vue";
import SolutionPartner from "./SolutionPartner.vue";
import ExplorePublications from "./ExplorePublications.vue";
import IngredientFunctions from "./IngredientFunctions.vue";
import SolutionPortfolio from "./SolutionPortfolio.vue";
import News from "./News.vue";

export default {
  components: {
    NavLinks: NavLinks,
    SiteSearch: SiteSearch,
    Food: Food,
    NonFood: NonFood,
    PharmaceuticalAndNutraceutical: PharmaceuticalAndNutraceutical,
    NewProductHighlights: NewProductHighlights,
    SolutionPartner: SolutionPartner,
    ExplorePublications: ExplorePublications,
    IngredientFunctions: IngredientFunctions,
    SolutionPortfolio: SolutionPortfolio,
    News: News,
  },

  props: [
    "text1",
    "text2",
    "text3",
    "text4",
    "text5",
    "text6",
    "text7",
    "text8",
    "text9",
    "text10",
    "text11",
    "textsearch",
    "textreadmore",
    "textviewmore",
    "textnoresult",
    "silogo",
    "siurl",
  ],

  data() {
    return {
      token: document.head.querySelector("[name~=csrf-token]").content,
      lang: document.documentElement.lang,
      firstload: true,
      keyword: "",
      search: "all",
      menus: [],
      items: {
        siteSearch: [],
        food: [],
        nonFood: [],
        pharmaceuticalAndNutraceutical: [],
        newProductHighlights: [],
        news: [],
      },
      reloadStyle: false,
      iconStyle: "fas fa-spinner fa-spin",
      debounce: this.createDebounce(),
    };
  },

  watch: {
    keyword(newValue) {
      this.getSearch();
    },
    search(newValue) {
      this.getSearch();
    },
    reloadStyle(newValue) {
      this.reloadStyle = newValue;
      this.iconStyle =
        newValue == "preloader-search--reload" ? "fas fa-spinner fa-spin" : "icon-search";
    },
  },

  methods: {
    createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 1200);
      };
    },

    getRawData(proxyObj) {
      if (isProxy(proxyObj)) {
        return toRaw(proxyObj);
      }

      return proxyObj;
    },

    isActive(value) {
      return value == this.search;
    },

    setLoading(value) {
      this.reloadStyle = value;
    },

    setItems(items) {
      if (items == undefined) {
        return [];
      } else {
        return items;
      }
    },

    setSearch(value) {
      let vm = this;
      let newValue = value;
      vm.debounce(() => {
        vm.search = newValue;
      }, 500);
    },

    updateItems(category, response) {
      this.items[category] = response;
    },

    checkParameterSearch() {
      var vm = this;

      let paraSearch = this.getParameterByName("search");
      if (paraSearch) {
        vm.keyword = paraSearch;
      }

      let url = new URL(window.location.href);
      let params = url.searchParams; // Get the search params from the URL
      params.delete("search"); // Remove the specific parameter (e.g., 'paramName')
      window.history.replaceState({}, "", url.toString()); // Update the URL without refreshing the page

      this.getSearch();
    },

    getSearch() {
      if (this.search == "all") {
        var vm = this;
        let $endpoint = `/api/${vm.lang}/search/${this.search}?token=${vm.token}&keyword=${vm.keyword}`;

        vm.reloadStyle = "preloader-search--reload";
        // vm.iconStyle = "fas fa-spinner fa-spin"

        axios
          .get($endpoint)
          .then(function (response) {
            if (vm.search == "all") {
              vm.items = response.data.data;
            }
          })
          .catch(function (error) {
            console.error(error);
          })
          .then(function () {
            vm.reloadStyle = "";

            vm.firstload = false;
          });
      }
    },

    getParameterByName(e, n) {
      n || (n = window.location.href), (e = e.replace(/[\[\]]/g, "\\$&"));
      var r = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(n);
      // return r ? r[2] ? decodeURIComponent(r[2].replace(/\+/g, " ")) : "" : null
      return r ? (r[2] ? r[2].replace(/\+/g, " ") : "") : null;
    },
  },

  mounted() {
    this.checkParameterSearch();
  },
};
</script>
