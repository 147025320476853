<template>

<div class="row mb-5" v-if="search == 'all' || search == 'site-search'">
    <div class="col-12">
        <h2 class="section__title mb-5">{{title}}</h2>
        <div class="card-deck card-deck--three">

            <div class="card card--site-search" v-for="(item, index) in this.items" :key="index">
                <div class="card__body">
                    <div class="card__title" v-html="$textHighlight(item.name ?? item.title, this.keyword)"></div>
                    <div class="card__detail" v-html="$textHighlight(item.page ?? item.body, this.keyword, false)"></div>
                </div>

                <div class="card__button">
                    <a class="btn btn--icon" :href="item.url" :target="item.target">
                        <div class="btn__title">{{textreadmore}}</div>
                        <div class="btn__icon"><i class="icon-arrow-right"></i></div>
                    </a>
                </div>
            </div>

        </div>

        <p v-if="items.length == 0">
            <span v-if="keyword">"<b>{{ keyword }}</b>" -</span> {{textnoresult}}
        </p>

    </div>
</div>

</template>


<script>

let axios = require('axios')
let _ = require('lodash');

export default {
    props: [
        'items',
        'title',
        'search',
        'keyword',
        'textreadmore',
        'textnoresult',
        'token',
        'lang',
    ],

    watch: {
        keyword(newValue) {
            this.getSearch();
        },
        search(newValue) {
            this.getSearch();
        },
    },

    methods: {

        getSearch() {

            if(this.search == 'site-search' ) {

                let vm = this;

                vm.$emit('setLoading', 'preloader-search--reload');

                let $endpoint = `/api/${vm.lang}/search/site-search?token=${vm.token}&search=${vm.search}&keyword=${vm.keyword}`
                axios.get($endpoint).then(function (response) {

                    vm.$emit('updateItems', 'siteSearch', response.data.data);
                })
                .catch(function (error) {
                    console.error(error);
                })
                .then(function () {

                    vm.$emit('setLoading', '');

                })

            }
        },

    },

    mounted() {
        // this.getSearch();
    },
}

</script>
