
<template>

    <div id="my-briefcase">

        <form v-bind:action="this.action" method="post">

            <input type="hidden" name="_token" v-bind:value="this.csrfToken">

            <template v-for="(item, index) in items" :key="index">

                <input type="hidden" name="briefcases[]" :id="'briefcase-id-'+item.id" :value="item.file ?? item.url">

            </template>

            <div class="block-sum">
                <div class="block-sum__text">
                    {{text}}
                    <input class="form-control text-center" name="total_file_size" size="5" type="text" :value="this.calculateFileSize" readonly>
                    <span id="fileSizeSuffix">MB.</span>
                </div>
                <div class="block-sum__button">
                    <button class="btn btn--primary" type="submit" :disabled="count > 0 ? false : true">
                        <span class="btn__title">{{text2}}</span>
                    </button>
                    <button class="btn btn--primary" type="reset" @click="removeAllDownload($event)" :disabled="count > 0 ? false : true">
                        <span class="btn__title">{{text3}}</span>
                    </button>
                </div>
            </div>
        </form>
    </div>

</template>

<script>

export default {

    props: [
        'items',
        'action',
        'filesize',
        'count',
        'text',
        'text2',
        'text3'
    ],
    data() {
        return {
            status: 0,
            calculateFileSize : 0,
            csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
    },
    watch: {
        status(newValue){

            this.status = newValue
        },

        filesize(newValue) {
            let bytes = newValue
            let sizes = ['Bytes', 'KB.', 'MB.', 'GB.', 'TB.'];
            if (bytes == 0) {
                this.calculateFileSize = 0;
                document.querySelector('#fileSizeSuffix').innerHTML = 'MB.';
                return
            }
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            this.calculateFileSize = (bytes / Math.pow(1024, i)).toFixed(2);

            document.querySelector('#fileSizeSuffix').innerHTML = sizes[i];
        }
    },
    methods: {

        removeAllDownload(event) {

            let allCheckBox = document.querySelectorAll('.card--investor-kit .card__body .custom-control-input:not([disabled])');

            allCheckBox.forEach(function (element) {
                if(element.checked) {
                    element.click()
                }
            });

            this.calculateFileSize = this.$emit("clearDownload")

            event.preventDefault()

        }
    }
}

</script>
