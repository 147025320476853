let Vue = require('vue')
// let { createVuetify } = require('vuetify')

import helper from './vue/components/helper'

import Banners from './vue/components/Banner.vue'
import Breadcrumbs from './vue/components/Breadcrumb.vue'
//import GoogleCustomSearch from './vue/components/GoogleCustomSearch.vue'
import AdvancedSearch from './vue/components/AdvancedSearch/App.vue'
import VueDownloadCenter from './vue/components/DownloadCenter/App.vue'

const App = { components: [] }

App.components.Banner = Banners
App.components.Breadcrumbs = Breadcrumbs
//App.components.GoogleCustomSearch = GoogleCustomSearch
App.components.AdvancedSearch = AdvancedSearch
App.components.DownloadCenter = VueDownloadCenter

Vue.createApp(App).use(helper).mount('#vue-app')