const myPlugin = {
    install(app) {
        app.config.globalProperties.$textHighlight = (html, keyword, getAll = true) => {

            if(html) {

                let position = html.toLowerCase().indexOf(keyword.toLowerCase());

                if(position != -1 && keyword != "") {
                    let regEx = new RegExp(keyword, "ig");
                    let newHtml = html.replace(regEx, function(str) {
                        return `<mark>${str}</mark>`;
                    });

                    if(getAll) {
                        return newHtml;
                    } else {
                        return newHtml.slice(position, position + 200);
                    }
                } else {
                    return html.slice(0, 200);
                }
            }

        }
    }
}
 
export default myPlugin;