<template>

    <ul class="nav nav--search">
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'all' }"
                @click="setSearch('all')">
                {{text1}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'site-search' }"
                @click="setSearch('site-search')">
                {{text2}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'food' }"
                @click="setSearch('food')">
                {{text3}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'non-food' }"
                @click="setSearch('non-food')">
                {{text4}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'pharmaceutical-and-nutraceutical' }"
                @click="setSearch('pharmaceutical-and-nutraceutical')">
                {{text5}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'new-product-highlights' }"
                @click="setSearch('new-product-highlights')">
                {{text6}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'solution-partner' }"
                @click="setSearch('solution-partner')">
                {{text7}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'explore-publications' }"
                @click="setSearch('explore-publications')">
                {{text8}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'ingredient-functions' }"
                @click="setSearch('ingredient-functions')">
                {{text9}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'solution-portfolio' }"
                @click="setSearch('solution-portfolio')">
                {{text10}}
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="javascript:;" target="_parent"  
                :class="{ active: this.search == 'news' }"
                @click="setSearch('news')">
                {{text11}}
            </a>
        </li>
    </ul>

</template>

<script>

export default {

    props: [
        'search',
        'text1',
        'text2',
        'text3',
        'text4',
        'text5',
        'text6',
        'text7',
        'text8',
        'text9',
        'text10',
        'text11',
    ],

    watch: {
        // search(newValue) {
        //     this.getSearch(newValue)
        // },
        // reloadStyle(newValue) {
        //     this.reloadStyle = newValue
        // }
    },

    methods: {

        isActive: function (value) {
            return value == this.search;
        },

        setSearch: function (value) {

            this.$emit('setSearch', value);
        }

    }

}

</script>