<template>

<div class="card-deck card-deck--three">

    <div class="card card--investor-kit" v-for="(year, yearIndex) in years" :key="yearIndex" :id="'year-' + year">
        <div class="card__year">{{ year }}</div>
        <div class="card__body">

            <!-- one report -->

            <template v-if="items[year]['ar']">
                <div class="card__item card__item--yearly">
                    <h4 class="card__title">{{text}}</h4>
                    <div class="card__block" v-if="items[year]['ar'][1]">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input"
                                :id="'id-' + items[year]['ar'][1].id"
                                :value="items[year]['ar'][1].id"
                                @click="addDownloadToBriefcase(year, items[year]['ar'][1], $event)">
                            <label class="custom-control-label" :for="'id-' + items[year]['ar'][1].id"></label>
                        </div>
                        <a :href="items[year]['ar'][1].file" target="_blank" class="card__text">FY</a>
                    </div>
                </div>
            </template>

            <template v-else-if="!items[year]['ar']">
                <div class="card__item card__item--yearly">
                    <h4 class="card__title">{{text}}</h4>
                    <div class="card__block">
                        <div class="custom-control custom-checkbox disabled">
                            <input type="checkbox" class="custom-control-input" disabled>
                            <label class="custom-control-label"></label>
                        </div>
                        <div class="card__text">FY</div>
                    </div>
                </div>
            </template>

            <!-- if there is one report item and current loop year less than 2022 then insert empty row -->
            <template v-else-if="year < (parseInt(lastar) + 3)">
                <div class="card__item card__item--yearly">
                        <h4 class="card__title">&nbsp;</h4>
                        <div class="card__block invisible">
                            <div class="custom-control custom-checkbox disabled">
                                <input type="checkbox" class="custom-control-input" disabled>
                                <label class="custom-control-label"></label>
                            </div>
                            <div class="card__text">&nbsp;</div>
                        </div>
                    </div>
            </template>

            <!-- financials -->
            <template v-if="items[year]['financials']">
                <div class="card__item card__item--quarter" >
                    <h4 class="card__title">{{text2}}</h4>

                    <div class="card__wrapper">

                        <!-- aways show 4 quarters-->
                        <template v-for="index in 4" :key="index">

                            <!-- if download item found on this quarter -->
                            <div class="card__block" v-if="items[year]['financials'][index]">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input"
                                        :id="'id-' + items[year]['financials'][index].id"
                                        :value="items[year]['financials'][index].id"
                                        @click="addDownloadToBriefcase(year, items[year]['financials'][index], $event)">

                                    <label class="custom-control-label" :for="'id-' + items[year]['financials'][index].id"></label>
                                </div>
                                <a :href="items[year]['financials'][index].file ?? items[year]['financials'][index].url" target="_blank" class="card__text">{{index == 4 ? 'FY' : 'Q'+ index}}</a>
                            </div>

                            <!-- if no item then disable checkbox -->
                            <div class="card__block" v-else>
                                <div class="custom-control custom-checkbox disabled">
                                    <input type="checkbox" class="custom-control-input" disabled>
                                    <label class="custom-control-label"></label>
                                </div>
                                <div class="card__text">{{index == 4 ? 'FY' : 'Q'+ index}}</div>
                            </div>

                        </template>
                    </div>
                </div>
            </template>

            <!-- mda -->
            <template v-if="items[year]['mda']">
                <div class="card__item card__item--quarter" >
                    <h4 class="card__title">{{text3}}</h4>

                    <div class="card__wrapper">

                        <!-- aways show 4 quarters-->
                        <template v-for="index in 4" :key="index">

                            <!-- if download item found on this quarter -->
                            <div class="card__block" v-if="items[year]['mda'][index]">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input"
                                        :id="'id-' + items[year]['mda'][index].id"
                                        :value="items[year]['mda'][index].id"
                                        @click="addDownloadToBriefcase(year, items[year]['mda'][index], $event)">

                                    <label class="custom-control-label" :for="'id-' + items[year]['mda'][index].id"></label>
                                </div>
                                <a :href="items[year]['mda'][index].file ?? items[year]['mda'][index].url" target="_blank" class="card__text">{{index == 4 ? 'FY' : 'Q'+ index}}</a>
                            </div>

                            <!-- if no item then disable checkbox -->
                            <div class="card__block" v-else>
                                <div class="custom-control custom-checkbox disabled">
                                    <input type="checkbox" class="custom-control-input" disabled>
                                    <label class="custom-control-label"></label>
                                </div>
                                <div class="card__text">{{index == 4 ? 'FY' : 'Q'+ index}}</div>
                            </div>

                        </template>
                    </div>
                </div>
            </template>

            <!-- slides -->
            <template v-if="items[year]['slides']">
                <div class="card__item card__item--quarter" >
                    <h4 class="card__title">{{text4}}</h4>

                    <div class="card__wrapper">

                        <!-- aways show 4 quarters-->
                        <template v-for="index in 4" :key="index">

                            <!-- if download item found on this quarter -->
                            <div class="card__block" v-if="items[year]['slides'][index]">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input"
                                        :id="'id-' + items[year]['slides'][index].id"
                                        :value="items[year]['slides'][index].id"
                                        @click="addDownloadToBriefcase(year, items[year]['slides'][index], $event)">

                                    <label class="custom-control-label" :for="'id-' + items[year]['slides'][index].id"></label>
                                </div>
                                <a :href="items[year]['slides'][index].file ?? items[year]['slides'][index].url" target="_blank" class="card__text">{{index == 4 ? 'FY' : 'Q'+ index}}</a>
                            </div>

                            <!-- if no item then disable checkbox -->
                            <div class="card__block" v-else>
                                <div class="custom-control custom-checkbox disabled">
                                    <input type="checkbox" class="custom-control-input" disabled>
                                    <label class="custom-control-label"></label>
                                </div>
                                <div class="card__text">{{index == 4 ? 'FY' : 'Q'+ index}}</div>
                            </div>

                        </template>
                    </div>
                </div>
            </template>

        </div>

        <div class="card__button">
            <h4 class="card__title">{{text5}}</h4>
            <div class="custom-control custom-checkbox" >
                <input type="checkbox" class="custom-control-input" :id="'all-' + year"
                    :value="year"
                    @click="addAllDownload($event)">
                <label class="custom-control-label" :for="'all-' + year"></label>
            </div>
        </div>
    </div>

</div>

</template>


<script>

    export default {

        props: [
            'items',
            'years',
            'lastar',
            'text',
            'text2',
            'text3',
            'text4',
            'text5'
        ],

        methods: {

            addDownloadToBriefcase(yearIndex, item, event) {

                let self = this;
                let elem = event.target;
                let year = yearIndex;

                let cardYear =  document.querySelector('#year-' + year);
                let allCheckBox = cardYear.querySelectorAll('.card__body .custom-control-input:not([disabled])');
                let allDownloadButton = cardYear.querySelector('#all-' + year);
                let checkAll = true;

                allCheckBox.forEach(function (element) {
                    if(!element.checked) {
                        checkAll = false
                        return;
                    }
                });

                allDownloadButton.checked = checkAll;

                if(elem.checked){

                    //console.log('addDownloadToBriefcase');
                    //console.log(item);

                    this.$emit("addBriefcase", item);

                }else {

                    self.removeDownloadFromBriefcase(item);

                }

            },

            removeDownloadFromBriefcase(item) {

                //console.log('removeDownloadFromBriefcase');

                this.calculateFileSize = this.$emit("removeBriefcase", item)

            },

            addAllDownload(event) {

                let self = this;
                let elem = event.target;
                let year = elem.value;

                let cardYear =  document.querySelector('#year-' + year);
                let allCheckBox = cardYear.querySelectorAll('.card__body .custom-control-input:not([disabled])');

                if(elem.checked) {
                    allCheckBox.forEach(function (element) {
                        if(!element.checked) {
                            element.click();
                        }
                    });
                }else {
                    allCheckBox.forEach(function (element) {
                        if(element.checked) {
                            element.click();
                        }
                    });
                }

            }
        }
    };

</script>
