<template>
    
<div class="row mb-5 pb-5" v-if="this.search == 'all' || search == 'solution-partner'">
    <div class="col-12">

        <h2 class="section__title mb-5">{{title}}</h2>

        <div class="card-deck card-deck--partner">
            <a class="card card--partner" :href="item.url" :target="item.target" v-for="(item, index) in computedItems" :key="index">
                <div class="card__wrapper">
                    <div class="card__bg" :style="{ backgroundImage: `url(${item.image})` }">
                    </div>
                    <div class="card__title" v-html="$textHighlight(item.title, this.keyword)"></div>
                </div>
            </a>            
        </div>

        <p v-if="items.length == 0">
            <span v-if="keyword">"<b>{{ keyword }}</b>" -</span> {{textnoresult}}
        </p>
        
    </div>
</div>

</template>


<script>

let axios = require('axios')

export default {
    props: [
        'items',
        'title',
        'search',
        'keyword',
        'textreadmore',
        'textviewmore',
        'textnoresult',
        'token',
        'lang',
    ],

    data() {
        return {
            limit : 8,
        }
    },

    computed:{
        computedItems(){
            //return (this.search == 'all' && !this.keyword ) ? this.items.slice(0,this.limit) : this.items;
            return this.items;
        },
    },

    watch: {
        keyword() {
            this.getSolutionPartner();
        },
        search() { 
            this.getSolutionPartner();
        },
    },

    methods: {

        getSolutionPartner() {

            if(this.search == 'solution-partner') {
                var vm = this;
                vm.$emit('setLoading', 'preloader-search--reload');

                let $endpoint = `/api/${vm.lang}/search/solution-partner?token=${vm.token}&keyword=${vm.keyword}`;
                axios.get($endpoint).then(function (response) {

                    vm.$emit('updateItems', 'solutionPartner', response.data.data);

                })
                .catch(function (error) {
                    console.error(error);
                })
                .then(function () {

                    vm.$emit('setLoading', '');
                })
            }

        },

    },

    mounted() {
        
    },

}

</script>