
<template>

    <div id="vue-download-center">

        <div class="preloader" :class="reloadStyle">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>

        <div class="section__content">
            <div class="container">
                <div class="row">
                    <div class="col-12">

                        <download :items="downloads" :years="years" :lastar="this.latestyear" :text="this.titlear" :text2="this.titlefs" :text3="this.titlemda" :text4="this.titleslide" :text5="this.textdownloadall" @addBriefcase="addToBriefcase($event)" @removeBriefcase="removeFromBriefcase($event)"></download>

                    </div>
                </div>

                 <div class="row mt-3">
                    <div class="col-12 text-center">

                        <briefcase :items="briefcases" :filesize="downloadFileSize" :count="countBriefcases" :action="this.actionform" :text="this.textfilesize" :text2="this.textdownload" :text3="this.textclear" @clearDownload="clearAllBriefcase($event)"></briefcase>

                    </div>
                </div>

            </div>

        </div>
    </div>

</template>

<script>

let axios = require('axios')
let _ = require('lodash');


import Downloads from './Download.vue'
import Briefcases from './Briefcase.vue'

export default {

    components: {
        Download: Downloads,
        Briefcase: Briefcases,
    },

    props: [
        'latestyear',
        'actionform',
        'titlear',
        'titlefs',
        'titlemda',
        'titleslide',
        'textdownloadall',
        'textfilesize',
        'textdownload',
        'textclear'
    ],

    data() {
        return {
            downloads: undefined,
            briefcases: [],
            load: false,
            countBriefcases: 0,
            downloadInBriefcases: [],
            checkedDownloads: [],
            downloadFileSize: 0,
            reloadStyle: false,
            lang : document.documentElement.lang,
            token : document.head.querySelector("[name~=csrf-token]").content
        }
    },
    watch: {
        load(newValue) {
            this.getDownload(newValue)
        },
        reloadStyle(newValue) {
            this.reloadStyle = newValue
        }
    },
    methods :
    {

        inArray (needle, haystack) {
            var length = haystack.length;
            for(var i = 0; i < length; i++) {
                if(haystack[i] == needle) return true;
            }
            return false;
        },

        addToBriefcase(item){

            if(!this.inArray(item, this.briefcases)){

                this.briefcases.push(item)
                this.checkedDownloads.push(item.id)
                this.downloadFileSize = parseInt(this.downloadFileSize) + parseInt(item.filesize)

            }

            this.countBriefcases = this.checkedDownloads.length

        },

        removeFromBriefcase(item){

            console.log('removeFromBriefcase')

            let removeIndex = this.briefcases.findIndex(element => element.id == item.id)

            this.briefcases.splice(removeIndex, 1)
            this.checkedDownloads.splice(removeIndex, 1)
            this.countBriefcases = this.checkedDownloads.length
            this.downloadFileSize = parseInt(this.downloadFileSize) - parseInt(item.filesize)

            return this.downloadFileSize

        },

        clearAllBriefcase(){

            console.log('clearAllBriefcase')

            this.briefcases = []
            this.checkedDownloads.splice(0)
            this.countBriefcases = this.checkedDownloads.length
            this.downloadFileSize = 0

            return this.downloadFileSize

        },

        getYear() {

            var vm = this

            vm.load = true

            // let $endpoint = `/api/${this.lang}/downloads/investor-kits/years?token=${this.token}`
            let $endpointFile = `/assets/static/files/years.json`

            axios
                .get($endpointFile)
                .then(function (response) {

                    vm.years = response.data

                })
                .catch(function (error) {
                    console.error(error);
                })

        },

        getDownload(load) {

            var vm = this

            vm.reloadStyle = "preloader--reload"

            console.log("Load : " + load)

            if(load){

                // let $endpoint = `/api/${this.lang}/downloads/investor-kits?token=${this.token}`
                let $endpointFile = `/assets/static/files/investor-kits.json`

                axios.get($endpointFile).then(function (response) {

                    vm.downloads = response.data.data;

                })
                .catch(function (error) {
                    console.error(error);
                })
                .then(function () {
                    vm.reloadStyle = ""
                })
            }

        }

    },
    mounted() {

        this.getYear()

    }

}

</script>
